import '@/css/app.css'
import Alpine from 'alpinejs'
import Headroom from 'headroom.js'
import collapse from '@alpinejs/collapse'
const headroom = () => {
    const headroom = new Headroom(document.querySelector('header'), {
        offset: 100,
        onTop: function () {
            document.querySelector('body').classList.add('headroom--top')
            document.querySelector('body').classList.remove('headroom--not-top')
        },

        onNotTop: function () {
            document.querySelector('body').classList.remove('headroom--top')
            document.querySelector('body').classList.add('headroom--not-top')
        }
    }).init()
}

// App main
const main = async () => {
    headroom()
    // Dit komt overeen met de twig var blockHandle
    // const blocks = []
    const components = [
        'fancybox',
        'swiper',
        'accordionComp',
        'langSwitcher',
        'googleMaps',
        'faq',
        'maps',
        'counter'
    ]

    const importPromises = []

    for (const comp of components) {
        const compElements = document.querySelectorAll(`.${comp}`)
        if (compElements.length === 0) {
            continue
        }

        const importPromise = import(`./components/${comp}.js`).then(
            ({ init }) => {
                compElements.forEach((comp) => {
                    importPromises.push(init(comp))
                })
            }
        )

        importPromises.push(importPromise)
    }

    importPromises.push(
        import('./base/animations.js').then(({ initAnimations }) =>
            initAnimations()
        ),
        import('./base/smooth-scroll').then(({ initSmoothScroll }) =>
            initSmoothScroll()
        )
    )

    importPromises.push(
        import('./base/menu.js').then(({ initMenu }) => initMenu())
    )
    return await Promise.all(importPromises)
}

main().then(() => {
    console.log('Vite <3')
    window.Alpine = Alpine

    Alpine.plugin(collapse)
    Alpine.start()
})

if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR')
    })
}
